<template>
  <div class="card">
    <h3 class="card-title">LISTAR ASIGNACIONES FAMILIARES</h3>
    <div>
      <a class="btn btn-sm btn-info d-flex center" download="" href="/AUTORIZACION OBLIGATORIA PARA EL COBRO DE AAFF.pdf" style="text-decoration: none">
        <icon feather="download" style="height: 12px"></icon>
        Autorización del otro progenitor
      </a>
    </div>
    <DataTable
        :showFilter="true"
        :header="headers"
        :body="body"
        :actionButton="actionButton2"
        :endpoint="asignacionesFamiliaresApi.getAll"
        @rowClicked="verAsignacionesFamiliares"
    />
  </div>
</template>
<script>
import {asignacionesFamiliaresApi} from "@/modules/percepcionesAsignacionFamiliar/services/asignacionesFamiliaresApi";
import {TipoEstadoAsignacionConstants} from "@/modules/percepcionesAsignacionFamiliar/constants/tipoEstadoAsignacion";
import moment from "moment/moment";
import {TipoAsignacionConstants} from "@/modules/percepcionesAsignacionFamiliar/constants/tipoAsignacion";
import DataTable from "@/components/elements/DataTable.vue";

export default {
  computed: {
    asignacionesFamiliaresApi() {
      return asignacionesFamiliaresApi
    }
  },
  components: {DataTable},
  name: "ListarAsignacionesFamiliares",
  data() {
    return {
      asignacionesFamiliares: null,
      headers: [
        {value: 'Empleado', sortable: 'percepcion_cargas_familias.nombre_empleado'},
        {value: 'Tipo', sortable: 'tipo_asignacion'},
        {value: 'Año', sortable: 'percepcion_cargas_familias.year'},
        {value: 'Estado', sortable: 'estado'},
        {value: 'Fecha de carga', sortable: 'changed_at'},
        {value: 'Fecha de aprobación', sortable: 'approved_at'}
      ],
      body: [
        {
          class: 'w-100 important text-bold',
          data: [{value: 'user.nombre'}]
        },
        {
          class: 'w-100 important',
          data: [
            {
              value: 'tipo_asignacion', mutator(tipoAsignacion) {
                if (tipoAsignacion === TipoAsignacionConstants.MATERNIDAD) {
                  return `<span style="display: none">${tipoAsignacion}</span>`
                }
                return `<span>${tipoAsignacion}</span>`
              }
            },
            {
              value: 'percepcion_maternidad', mutator(percepcion_maternidad) {
                if (percepcion_maternidad?.tipo_percepcion) {
                  return `<span>${percepcion_maternidad.tipo_percepcion}</span>`
                }
                return `<span style="display: none"><span>`
              }
            }
          ]
        },
        {
          class: 'w-100 important text-bold',
          data: [{value: 'percepcion_cargas_familia', mutator(percepcionCargasFamilia) {
              return percepcionCargasFamilia?.year ?? '-'
            }
          }]
        },
        {
          class: 'important',
          data: [{
            value: 'estado', mutator(estado) {
              if (estado === TipoEstadoAsignacionConstants.APROBADO) {
                return `<b class="text-success">${estado}</b>`
              }
              if (estado === TipoEstadoAsignacionConstants.EN_REVISION) {
                return `<b class="text-primary">${estado}</b>`
              }
              if (estado === TipoEstadoAsignacionConstants.CON_OBSERVACIONES) {
                return `<b class="text-warning">${estado}</b>`
              }
              if (estado === TipoEstadoAsignacionConstants.RECHAZADO) {
                return `<b class="text-danger">${estado}</b>`
              }
            }
          }]
        },
        {
          class: 'important',
          data: [{
            value: 'changed_at', mutator: this.normalDate
          }]
        },
        {
          class: 'important',
          data: [{
            value: 'approved_at', mutator: this.normalDate
          }]
        }
      ],
      actionButton: {
        text: "Crear DDJJ Maternidad",
        action: () => {
          this.$router.push({name: 'CrearPercepcionMaternidad'})
        },
      },
      actionButton2: {
        text: "Crear DDJJ Cargas familia",
        action: () => {
          this.$router.push({name: 'CrearPercepcionCargasFamilia'})
        },
      }
    };
  },
  mounted() {
    asignacionesFamiliaresApi.getAll().then((asignacionesFamiliares) => {
      this.asignacionesFamiliares = asignacionesFamiliares
    })
  },
  methods: {
    normalDate(value) {
      if(!value){
        return '-'
      }
      let date = moment(value)
      return date.format('DD/MM/YYYY');
    },
    verAsignacionesFamiliares(asignacionFamiliar){
      this.$router.push({name: 'VerAsignacionesFamiliares', params: {id: asignacionFamiliar.id}})
    }
  }
};
</script>

<style scoped>

</style>
